.App {
  text-align: center;
  background-color: white !important;
}

body, html {
  font-family: 'SF Pro Display', sans-serif;
  overflow: hidden;
  /*margin-top: 50px;*/
}

/* TODO: removed */
/* html {
  height: 100vh;
  overflow: hidden;
} */

.App-logo {
  height: 7vmin;
  min-height: 60px;
  pointer-events: none;
}


.App-logo-min {
  height: 7vmin;
  pointer-events: none;
  margin-top: 24px;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logox {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #888888;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.align-right {
  display: flex !important;
  justify-content: right !important;
}


/*  ====================================  */


/* General Styles */
.app {
  display: flex;
  flex-direction: row;
  /* TODO: edit start */
  height: 100%;
  /* TODO: edit end */
  overflow: hidden;
  max-width: 100vw !important;
  width: 100vw;
  padding: 0 !important;
  margin: 0 !important;
}

/* Left Panel */
.left-panel {
  width: 265px;
  display: flex;
  height: 100vh;
  flex-direction: row-reverse;
  justify-content: center;
  background-color: #ffffff99;
  transition: width 0.3s;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  z-index: 20000;
}

.chatHeader{
  background-color: #ffffff88;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  z-index: 1000;
  margin-left: 0;
  position: relative;
  /* TODO: removed */
  /* padding-top: 24px; */
}

.SavedSearch {
  display: none !important;
}

.left-panel .lpbutton {
  display: flex;
  border: none;
  background-color: unset;
  width: 50px;
  align-items: center;
  position: absolute;
  right: 0;
  top: 48%;
}

.NewButton {
  margin-top: 16px !important;
}

.left-panel.collapsed {
  width: 80px;
  overflow: hidden;
}

/* Main Content */
.main-content-new {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  height: calc(100vh - 32px);
  margin-top: 16px;
  margin-left: 16px;
}

.top-panel {
  flex: 1;
  min-height: 430px;
}

.bottom-panel-container {
  /* TODO: removed */
  /* height: 100% !important; */
  /* TODO: added start */
  height: 100dvh;
  /* TODO: added end */
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  margin-left: 16px;
  margin-bottom: 16px;
  border-radius: 20px !important;
  /*background-color: brown;*/

}

.chatBody {
  flex: 1;
  position: relative;
  margin-left: 16px;
  display: flex;
  /* TODO: edit start */
  /* margin-top: -78px; */
  /* padding-top: 90px; */
  background-color: white;
}

.chatBody .bpbutton {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.chatHeader {
  border-radius: 20px;
  margin: 0;
  /*margin-left: 24px;*/
}

/* Right Panel */
.right-panel {
  width: 400px;
  display: flex;
  transition: transform 0.3s;
  transform: translateX(100%);
  z-index: 10010;
}

.right-panel.visible {
  transform: translateX(0);
}

.right-panel .mobDetails {
  display: none;
  /*margin: 10px;*/
}

/* Detailed Panel */
.detailed-panel {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.9);
  display: none;
  height: 0;
  width: 0;
  justify-content: center;
  align-items: center;
  z-index: 200000;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

.detailed-panel button {
  position: absolute;
  top: 40px;
  right: 20px;
}

.spacer50 {
    display: block;
    height: 50px;
}

.css-9tj150-MuiButton-endIcon {
  margin: 0 !important;
}

.css-15v22id-MuiAccordionDetails-root {
  padding: 8px 16px 8px 16px !important;
}

/* Mobile Styles */
@media (max-width: 768px) {

  .main-content-new {
        display: block !important;
        margin-bottom: 0 !important;
        margin-top: 0;
        overflow: hidden;
        height: 100%;
        position: relative;
        top: 0;
  }

  .NewButton {
    margin-top: 16px !important;
  }

  .css-9tj150-MuiButton-endIcon {
    margin: 0 !important;
    width: 30px;
  }

  .newRequest{
    min-width: 30px;
    width: 100%;
  }

  .newRequestIcon {
    width: 1em;
    height: 1em;
    margin-left: 0px !important;
  }

  .SavedSearch {
    display: block !important;
  }

  .textEntry {
    margin-bottom: 0 !important;
    box-shadow: none !important;
    border-radius: 0 !important;
    /* TODO: add start */
    position: fixed;
    bottom: 0;
    /* TODO: add end */
  }

  .InputField {
    padding-left: 32px !important;
  }

  .chat {
    border-radius: 0;
    margin: 0;
    margin-left: 24px;
  }

  .chatHeader {
    border-radius: 0;
    margin: 0;
    padding-top: 16vh;
  }

  .left-panel {
    position: fixed;
    display: flex;
    flex-direction: row;
    width: 100%;
    transform: translateX(0%);
    z-index: 20000;
    background-color: rgba(255, 255, 255, 0.8) !important;
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    /*padding-top: 32px !important;*/
  }

  .left-panel > .lpbutton {
    display: flex;
    width: 50px;
    border: none;
    flex-direction: row-reverse;
    background-color: unset !important;
  }

  /*.left-panel button {*/
  /*  display: flex;*/
  /*  border: none;*/
  /*  background-color: unset !important;*/
  /*  width: 50px;*/
  /*}*/

  .top-panel {
    display: none !important;
  }

  .left-panel.collapsed {
    width: 60px;
    overflow: hidden;
    transform: translateX(0);
  }

  .right-panel {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: calc(100% - 50px);
    height: calc(100vh - 74px);
    transform: translateX(100%);
    z-index: 10000;
    margin: 0 !important;
    background-color: white;
  }

  .right-panel.visible {
    transform: translateX(0);
    border-radius: 0;
    background-color: white;
    margin: 0;
  }

  .rpHeader {
    padding-top: 30px !important;
  }

  .right-panel .mobDetails {
    display: block;
    /*margin: 10px;*/
  }

  .detailed-panel {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    display: none;
    justify-content: center;
    align-items: center;
    z-index: 200000;
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
  }

  .bottom-panel-container {
    flex: 1;
    border-radius: 20px !important;
    margin-left: 32px;
    margin-bottom: -7px;
    /*margin-top: 80px !important;*/
    position: relative;
    top: 0;
    /*background-color: #61dafb;*/
  }

  .chatBody {
    flex: 1;
    border-radius: 0 !important;
    margin-left: 40px;
    margin-bottom: 0px;
    display: flex;
    flex-direction: column;
    margin-top: -78px;
    padding-top: 90px;
  }

  .top-panel{
    flex: 0 !important;
    min-height: 0;
  }

  .spacer50 {
    height: 50px !important;
  }

  .productItems {
    padding: 8px !important;
  }

}
